@import 'styles/includes';

.Hero {
    &__OffsetContent {
        @extend %container;
        margin-top: -36px;
        position: relative;

        @include media(m) {
            margin-top: -62px;
        }
    }

    &__Title {
        @extend %h4;
        margin-top: 2.4rem;
        color: $colorBlue;
    }

    &__ButtonContainer {
        margin-top: 1.4rem;
        display: grid;
        gap: 24px;

        @include media(m) {
            grid-template-columns: repeat(2, 1fr);
        }

        @include media(ml) {
            grid-template-columns: repeat(3, 1fr);
        }
    }
}
